//
// tables.scss
//

//Table centered (Custom)
.table-centered {

    th,
    td {
        vertical-align: middle !important;
    }
}


.table-sm {
    .action-icon {
        font-size: 1rem;
    }
}

// Tables fluid
.table-nowrap {

    th,
    td {
        white-space: nowrap;
    }
}

// Dark Mode light and dark table
html[data-bs-theme="dark"] {
    .table-light {
        --#{$prefix}table-bg: #3e4954;
        --#{$prefix}table-border-color: #{$table-group-separator-color};
        color: var(--#{$prefix}body-color);
    }

    .table-dark {
        --#{$prefix}table-bg: var(--#{$prefix}light);

        tbody,
        tr {
            border-color: var(--#{$prefix}light);
        }
    }
}