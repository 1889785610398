//
// topbar.scss
//

.navbar-custom {
    top: 0;
    position: sticky;
    z-index: 1000;
    background: $topbar-bg;
    min-height: $topbar-height;
    transition: $transition-base;
    border-bottom: $border-width solid var(--#{$prefix}border-color);

    .topbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0 $spacer;


        ul {
            list-style-type: none;
            margin-bottom: 0;
        }


        li {
            max-height: $topbar-height;

            .nav-link {
                &.show {
                    color: $topbar-item-hover-color;
                }
            }
        }

        .nav-link {
            padding: 0;
            position: relative;
            color: $topbar-item-color;
            display: flex;
            align-items: center;
            height: $topbar-height;
            cursor: pointer;
            border-radius: 9999px;

            &:hover {
                color: $topbar-item-hover-color;
            }
        }
    }
}


.nav-user {
    padding: 0 12px !important;

    img {
        height: 32px;
        width: 32px;
    }
}

html[data-topbar-color="brand"],
html[data-topbar-color="dark"],
html[data-bs-theme="dark"] {
    .navbar-custom {
        .logo-box {
            .logo-light {
                display: block;
            }

            .logo-dark {
                display: none;
            }
        }
    }
}


// Topbar light/Dark Mode icon 
html[data-bs-theme="dark"] {
    #light-dark-mode {
        .bx-moon {
            &::before {
                content: "\ec34";
            }
        }
    }
}

//  Topbar Menu Toggle button
.button-toggle-menu {
    border: none;
    color: $topbar-item-color;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
    z-index: 1;
    position: relative;
}

// fullscreen exit icon
.fullscreen-enable {
    .fe-maximize::before {
        content: "\e88d";
    }
}

@media (max-width: 375px) {
    .navbar-custom {
        .button-toggle-menu {
            width: auto;
        }

        .logo-box {
            display: none !important;
        }
    }

}

@include media-breakpoint-down(sm) {
    .navbar-custom {
        .topbar-menu {
            position: initial;
        }

        .dropdown {
            position: static;

            .dropdown-menu {
                left: 10px !important;
                right: 10px !important;
            }
        }
    }
}


@include media-breakpoint-down(md) {

    .navbar-custom {
        padding: 0 $spacer * 0.5;

        .logo-box {
            display: flex;

            .logo-lg {
                display: none;
            }

            .logo-sm {
                display: block;
            }
        }
    }
}


@include media-breakpoint-up(xl) {
    .navbar-custom {
        .logo-box {
            display: none;
        }
    }
}